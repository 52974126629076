import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { FormSection, FormSubsection, Textarea } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { BOX_MANAGEMENT_INFINITE_QUERY_KEY } from 'hooks/box-knowledge-mangement';
import { updateBoxConfiguration } from 'services/api-box-mangement';
import { getBoxConfigurationStatusQueryKey } from 'shared/Box/useBoxConfigurationStatus';
import { TextInput } from 'shared/TextInput';
import { getBoxSettingsPageBreadcrumbs } from '../shared/breadcrumbs';
import {
  BOX_CONFIGURATION_PAGE_DESCRIPTION,
  BOX_CONFIGURATION_PAGE_TITLE,
} from '../shared/header';
import styles from './styles.module.css';

export const BoxConfigureAppCredentials: React.FC<RouteComponentProps> = ({
  uri,
}) => {
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const { id: programId } = useProgram();
  const client = useQueryClient();

  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [publicKeyId, setPublicKeyId] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [passphrase, setPassphrase] = useState('');
  const [enterpriseId, setEnterpriseId] = useState('');

  const hasError =
    !clientId ||
    !clientSecret ||
    !publicKeyId ||
    !privateKey ||
    !passphrase ||
    !enterpriseId;

  const onSave = useCallback(async () => {
    setShouldConfirm(false);
    setIsSaving(true);

    try {
      await updateBoxConfiguration(programId, {
        boxAppSettings: {
          clientId,
          clientSecret,
          appAuth: {
            publicKeyId,
            privateKey,
            passphrase,
          },
        },
        enterpriseId,
      });

      client.invalidateQueries(BOX_MANAGEMENT_INFINITE_QUERY_KEY);
      client.invalidateQueries(getBoxConfigurationStatusQueryKey(programId));
    } catch (err) {
      setFlashMessage({
        severity: 'error',
        message:
          'There was an error updating the Box app configuration. Please try again.',
      });
      throw err;
    } finally {
      setIsSaving(false);
      navigate(`${uri}/..`);
    }
  }, [
    client,
    clientId,
    clientSecret,
    enterpriseId,
    navigate,
    passphrase,
    privateKey,
    programId,
    publicKeyId,
    setFlashMessage,
    uri,
  ]);

  return (
    <>
      <FormPage
        title={BOX_CONFIGURATION_PAGE_TITLE}
        description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
        breadcrumbs={getBoxSettingsPageBreadcrumbs('App Configuration')}
        actions={[
          {
            label: 'Save',
            disabled: hasError,
            onClick: () => setShouldConfirm(true),
            isLoading: isSaving,
          },
        ]}
      >
        <FormSection
          title="Configure Box App"
          description="Fill in the form below with the values from the config.json from Box to set the Box app this program uses."
        >
          <FormSubsection title="Client ID" divider={false}>
            <TextInput
              onChange={setClientId}
              value={clientId}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Client Secret" divider>
            <TextInput
              onChange={setClientSecret}
              value={clientSecret}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Public Key ID" divider={false}>
            <TextInput
              onChange={setPublicKeyId}
              value={publicKeyId}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Private Key" divider={false}>
            <Textarea
              onChange={setPrivateKey}
              value={privateKey}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Passphrase" divider>
            <TextInput
              onChange={setPassphrase}
              value={passphrase}
              className={styles.textInput}
            />
          </FormSubsection>
          <FormSubsection title="Enterprise ID" divider>
            <TextInput
              onChange={setEnterpriseId}
              value={enterpriseId}
              className={styles.textInput}
            />
          </FormSubsection>
        </FormSection>
      </FormPage>

      {shouldConfirm && (
        <ConfirmModal
          title="Submit New App Configuration?"
          confirmLabel="Submit"
          onCancel={() => setShouldConfirm(false)}
          onConfirm={onSave}
        >
          <p>
            This will replace the existing app configuration. This action cannot
            be undone.
          </p>
          <br />
          <p>
            If you are switching to a new Box app, all your existing Box
            mappings, including folder mappings, will no longer work. If this is
            the case, you will need to <strong>Clear All Mappings</strong>{' '}
            (under <strong>Administrative Actions</strong>) after submitting the
            new configuration.
          </p>
        </ConfirmModal>
      )}
    </>
  );
};
