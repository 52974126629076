import React from 'react';
import { Button, FieldInput } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import { VideoFieldData } from 'models/donkey';
import { FieldFormProps } from 'components/publisher/blocks/forms/useFieldForm';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { List } from './List';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';
import { useVideoCaption } from '../../hooks/useVideoCaption';
import { CaptionJobs } from './CaptionJobs';

export const VideoCaptions: React.FC<{
  fieldData: VideoFieldData;
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  onCaptionUpdated: () => void;
  filename?: string;
  locale?: string;
}> = ({ fieldData, onChange, onCaptionUpdated, filename, locale }) => {
  const {
    fetchedCaptions,
    onListItemEdit,
    onListItemRemove,
    openSettings,
    CaptionSettings,
    isDisabled,
    captionsError,
  } = useVideoCaption({
    fieldData,
    onChange,
    onCaptionUpdated,
  });

  const {
    isLoadingFeatureFlags,
    isCaptionTranscriptionEnabled,
  } = useCaptionsJobs();

  return (
    <>
      <FieldInput>
        <Text.FieldLabel>Captions</Text.FieldLabel>
        {isCaptionTranscriptionEnabled && <CaptionJobs />}
        <List
          locale={locale}
          captions={fetchedCaptions}
          onEdit={onListItemEdit}
          onRemove={onListItemRemove}
          filename={filename || ''}
          videoId={fieldData.video_id}
        />
        <br />
        <Button
          block
          icon={
            isLoadingFeatureFlags ? (
              <LoadingSpinner size="small" />
            ) : (
              <Text.PageHeading color={Text.color.gray00}>+</Text.PageHeading>
            )
          }
          label="Add Captions"
          onClick={openSettings}
          disabled={isDisabled || isLoadingFeatureFlags}
        />
        {captionsError && (
          <Text.Body color={Text.color.redFull}>{captionsError}</Text.Body>
        )}

        {CaptionSettings}
      </FieldInput>
    </>
  );
};
