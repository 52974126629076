import camelcaseKeys from 'camelcase-keys';
import qs from 'qs';
import snakecaseKeys from 'snakecase-keys';
import { Journey, JourneyListItem } from 'models/journeys/journey';

import { ParameterizedFilters } from 'models/journeys/filter';
import {
  isStartStepError,
  JourneyErrors,
} from 'models/journeys/journey-errors';

import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { postHeaders } from 'utility/post-headers';
import { Design } from 'models/design';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';
import {
  deserializeJourney,
  deserializeJourneyListItems,
  JourneyCollectionData,
  serializeJourney,
} from './serializers/journey';
import { deserializeJourneyErrors } from './serializers/journey-errors';
import { NotFoundError } from './Errors/NotFoundError';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type QueryParameters = {
  programId: number;
  search?: string;
  page?: number;
  pageSize?: number;
  sortBy?: SortColumn;
  sortDirection?: 'asc' | 'desc';
};

export type JourneyActionParams = {
  programId: number;
  journeyId: number;
};

export type FetchJourneyParams = JourneyActionParams;
export type ArchiveJourneyParams = JourneyActionParams;
export type UnArchiveJourneyParams = JourneyActionParams;
export type DraftJourneyParams = JourneyActionParams;
export type StopJourneyParams = JourneyActionParams;

export type FetchNewJourneyParams = {
  programId: number;
  templateId?: number;
};

export type UpdateJourneyParams = {
  programId: number;
  journey: Journey;
};

export type ValidateJourneyParams = {
  programId: number;
  journey?: Journey;
};

export type SortColumn = 'created_at';

export const fetchJourneys = async (
  props: QueryParameters,
  parameterizedFilters: ParameterizedFilters
): Promise<JourneyCollectionData> => {
  const { programId, ...queryProps } = props;
  const query = qs.stringify(
    snakecaseKeys({ ...queryProps, ...parameterizedFilters }),
    {
      arrayFormat: 'brackets',
    }
  );

  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys?${query}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((json) => camelcaseKeys(json, { deep: true }))
      .then(deserializeJourneyListItems);
  }
  throw new Error(`Error fetching Journeys: ${response.status}`);
};

export const fetchJourney = async (
  props: FetchJourneyParams
): Promise<Journey> => {
  const { programId, journeyId } = props;

  try {
    const response = await request(
      `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}`
    );
    if (response.status === 200) {
      return response
        .json()
        .then(({ data }) => camelcaseKeys(data, { deep: true }))
        .then(deserializeJourney);
    }
    throw new Error('Server error');
  } catch (e) {
    if (e instanceof NotFoundError) {
      throw new Error(
        "Journey doesn't exist or the user does not have access to it."
      );
    }

    throw e;
  }
};

export const fetchNewJourney = async (
  props: FetchNewJourneyParams
): Promise<Journey> => {
  const { programId, templateId } = props;

  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/new${
      templateId ? `?template_id=${templateId}` : ''
    }`
  );
  if (response.status === 200) {
    return response
      .json()
      .then(({ data }) => camelcaseKeys(data, { deep: true }))
      .then(deserializeJourney);
  }
  throw new Error(`Error fetching new Journey: ${response.status}`);
};

export const upsertJourney = async (
  props: UpdateJourneyParams
): Promise<Journey> => {
  const { programId, journey } = props;

  const serializedJourney = serializeJourney(journey);
  const errorMessage = `Error ${journey.id ? 'updating' : 'creating'} journey`;

  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys${
      journey.id ? `/${journey.id}` : ''
    }`,
    {
      method: journey.id ? 'PUT' : 'POST',
      body: JSON.stringify(snakecaseKeys(serializedJourney, { deep: true })),
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );

  if (response.status === 200) {
    return response
      .json()
      .then(({ data }) => deepCamelcaseKeys(data))
      .then((serverJourney) => {
        const startStepId = journey.draftGraph?.steps.find(
          ({ type }) => type === 'start'
        )?.id;
        return deserializeJourney(serverJourney, startStepId);
      });
  }

  throw new Error(errorMessage);
};

export const copyLiveToDraft = async (
  props: DraftJourneyParams
): Promise<Journey> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/copy_live_to_draft`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response
      .json()
      .then(({ data }) => deepCamelcaseKeys(data))
      .then(deserializeJourney);
  }
  throw new Error(
    `Error copying Journey live graph to draft: ${response.status}`
  );
};

export const validateJourney = async (
  props: ValidateJourneyParams
): Promise<{ errors?: JourneyErrors }> => {
  const { programId, journey } = props;

  if (!journey) {
    return { errors: {} };
  }
  const serializedJourney = serializeJourney(journey);

  try {
    const response = await request(
      `${apiRoot}/samba/programs/${programId}/journeys/validate?strict=true`,
      {
        method: 'POST',
        body: JSON.stringify(snakecaseKeys(serializedJourney, { deep: true })),
        headers: {
          'Content-Type': 'application/json',
          'x-requested-with': 'XMLHttpRequest',
        },
      }
    );

    if (response.status === 200) {
      return { errors: undefined };
    }
    throw new Error(`Error validating journey: ${response.status}`);
  } catch (e) {
    if (e instanceof Error) {
      const startStepId = journey.draftGraph?.steps.find(
        ({ type }) => type === 'start'
      )?.id;
      return { errors: deserializeJourneyErrors(e, startStepId) };
    }
    return { errors: {} };
  }
};

export const useValidateJourney = (
  props: ValidateJourneyParams
): { errors?: JourneyErrors; isLoading: boolean } => {
  const { programId, journey } = props;

  const { data: journeyErrors, isLoading } = useQuery(
    ['validate_journey', programId, journey?.id],
    () => validateJourney({ programId, journey }),
    {
      enabled:
        journey &&
        journey.id !== undefined &&
        journey.draftGraph?.executionState === 'verifying',
      select: (data) => data.errors,
    }
  );

  return {
    errors: journeyErrors,
    isLoading,
  };
};

export const publishJourney = async (
  props: UpdateJourneyParams
): Promise<{ errors?: JourneyErrors }> => {
  const { programId, journey } = props;

  // If the journey has a live graph, we are promoting it, otherwise we are publishing it
  const endpoint = journey.liveGraph ? 'promote' : 'publish';

  try {
    const response = await request(
      `${apiRoot}/samba/programs/${programId}/journeys/${journey.id}/${endpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-requested-with': 'XMLHttpRequest',
        },
      }
    );
    if (response.status === 200) {
      return {};
    }
    throw new Error(`Error publishing journey: ${response.status}`);
  } catch (e) {
    if (e instanceof Error) {
      const startStepId =
        journey.draftGraph?.steps.find(({ type }) => type === 'start')?.id ??
        '';
      const errors = deserializeJourneyErrors(e, startStepId);
      const stepErrors = errors.graph?.[startStepId];
      const startStepErrors = isStartStepError(stepErrors)
        ? stepErrors
        : undefined;
      if (startStepErrors?.rootStepId && journey.draftGraph?.rootStepId) {
        // The root step id may not be originally returned from the server if the
        // journey graph was saved with an empty start configuration. When the graph
        // is deserialized to create the "virtual start step", the root step can be
        // derived from the existing steps of the graph, and the error should be
        // deleted.
        delete startStepErrors.rootStepId;
      }

      if (Object.keys(errors).length > 0) {
        throw new Error(JSON.stringify(errors));
      }
      throw new Error(JSON.stringify({ journey: 'Error publishing journey' }));
    }

    throw new Error(JSON.stringify({ journey: 'Error publishing journey' }));
  }
};

export const archiveJourney = async (
  props: ArchiveJourneyParams
): Promise<JourneyListItem> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/archive`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json));
  }
  throw new Error(`Error archiving journey: ${response.status}`);
};

export const unArchiveJourney = async (
  props: UnArchiveJourneyParams
): Promise<JourneyListItem> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/unarchive`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json));
  }

  throw new Error(`Error unarchiving journey: ${response.status}`);
};

export function useUnArchiveJourneyMutation(
  onSuccess?: () => void,
  onError?: () => void
): {
  isLoading: boolean;
  mutateUnArchive: UseMutateFunction<
    JourneyListItem,
    unknown,
    UnArchiveJourneyParams
  >;
} {
  const { isLoading, mutate: mutateUnArchive } = useMutation(
    ['journey_archive'],
    (data: UnArchiveJourneyParams) => unArchiveJourney(data),
    { onSuccess, onError }
  );
  return {
    isLoading,
    mutateUnArchive,
  };
}

export const stopJourney = async (
  props: StopJourneyParams
): Promise<JourneyListItem> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/stop`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json));
  }

  throw new Error(`Error stop journey: ${response.status}`);
};

export const pauseJourney = async (
  props: JourneyActionParams
): Promise<JourneyListItem> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/pause`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json));
  }

  throw new Error(`Error pause journey: ${response.status}`);
};

export const resumeJourney = async (
  props: JourneyActionParams
): Promise<JourneyListItem> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/resume`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json));
  }

  throw new Error(`Error resuming journey: ${response.status}`);
};

export const deleteJourneyDraft = async (
  props: JourneyActionParams
): Promise<JourneyListItem> => {
  const { programId, journeyId } = props;
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/destroy_draft`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
      },
    }
  );
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json));
  }

  throw new Error(`Error stop journey: ${response.status}`);
};

export function useStopJourneyMutation(
  onSuccess?: () => void,
  onError?: () => void
): {
  isLoading: boolean;
  mutateStop: UseMutateFunction<JourneyListItem, unknown, StopJourneyParams>;
} {
  const { isLoading, mutate: mutateStop } = useMutation(
    ['journey_stop'],
    (data: StopJourneyParams) => stopJourney(data),
    { onSuccess, onError }
  );
  return {
    isLoading,
    mutateStop,
  };
}

export function useDeleteJourneyDraftMutation(
  onSuccess?: () => void,
  onError?: () => void
): {
  isLoading: boolean;
  mutateDeleteDraft: UseMutateFunction<
    JourneyListItem,
    unknown,
    JourneyActionParams
  >;
} {
  const { isLoading, mutate: mutateDeleteDraft } = useMutation(
    ['journey_delete_draft'],
    (data: JourneyActionParams) => deleteJourneyDraft(data),
    { onSuccess, onError }
  );
  return {
    isLoading,
    mutateDeleteDraft,
  };
}

export async function cancelJourneyProcessing({
  programId,
  journeyId,
}: {
  programId: number;
  journeyId: number;
}): Promise<Journey> {
  const response = await request(
    `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/cancel_processing`,
    {
      method: 'POST',
    }
  );

  if (!response.ok) {
    throw new Error(
      `Error cancelling journey processing, status: ${response.status}`
    );
  }

  const json = (await response.json()) as Journey;
  return camelcaseKeys(json);
}

export const sendTestJourneyCommunication = async (
  subject: string,
  previewText: string,
  design: Design,
  programId: number,
  recipients: number[],
  preferOutlook365: boolean
): Promise<void> => {
  if (design.id === 'new') {
    throw new Error('Error: journey communication is not persisted');
  }

  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/content_preview`,
    {
      body: JSON.stringify({
        blocks: design.blocks,
        styles: design.styles,
        meta: design.meta,
        subject,
        preview_text: previewText,
        type: 'email',
        recipients,
        preferOutlook365,
        target: 'design',
      }),
      method: 'POST',
      headers: postHeaders(),
    }
  );

  if (response.status !== 200) {
    throw new Error('Error sending preview email');
  }
};
