import { useEffect, useCallback, useRef, useState } from 'react';
import FroalaEditor from 'froala-editor';
import { usePermissions } from 'contexts/permissions';
import { usePublisher } from 'contexts/publisher';
import {
  formatAttributeDisplayName,
  isCustom,
  isDuplicateStandard,
} from 'models/campaign-variable';
import { FlagValueType } from 'models/feature-flag';
import { LiquidVariable } from 'models/liquid-variable';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { uniqueId } from 'hooks/useUniqueId';
import { useJourneyState } from 'contexts/journeys/journey';
import styles from './liquid-variables.module.css';
import { fixCursedCursor, setCursor } from './fixCursedCursor';
import { fixFormatting, setColor } from './fixFormatting';
import { Firefox, Safari } from './common';
import { useDefaultValueForm } from './useDefaultValueForm';
import { objectSvg } from './objectSvg';
import { useLiquidVariablesQuery } from './useQueries';

// ported from socialchorus/bossanova
export function useFroalaLiquidPlugin(
  programId: number,
  _userId: number,
  enabled?: FlagValueType
): {
  events: {
    initializationDelayed: (this: FroalaEditor) => void;
  };
} {
  const { id, liquidVariables: publisherLiquidVariables } = usePublisher();
  const { journey } = useJourneyState();
  const { data } = useLiquidVariablesQuery(programId, id);

  const [liquidVariables, setLiquidVariables] = useState<
    LiquidVariable[] | undefined
  >();

  useEffect(() => {
    if (publisherLiquidVariables) {
      setLiquidVariables(publisherLiquidVariables);
    } else if (data) {
      setLiquidVariables(data);
    }
  }, [data, publisherLiquidVariables]);
  useDefaultValueForm();

  const variablesRef = useRef<LiquidVariable[] | undefined>([]);

  const setVariables = useCallback(
    (newVariables: LiquidVariable[] | undefined) => {
      variablesRef.current = newVariables;
    },
    []
  );

  const { data: personalizedFieldsFlagV2 } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFieldsV2'
  );

  useEffect(() => {
    setVariables(liquidVariables);
  }, [liquidVariables, setVariables]);

  const {
    permissions: { configureSetPersonalizedFields },
  } = usePermissions();

  if (enabled) {
    FroalaEditor.DefineIconTemplate('object_svg', objectSvg);
    FroalaEditor.DefineIcon('liquid', {
      NAME: 'object_svg',
      template: 'object_svg',
    });
    // froala doesn't want an arrow function
    // eslint-disable-next-line func-names
    FroalaEditor.PLUGINS.liquid = function (initialEditor: FroalaEditor) {
      const editor = initialEditor;

      function insertVariable(key: string) {
        const variable = variablesRef.current?.find((v) => {
          return v.key === key;
        });
        const liquidId = uniqueId();
        if (variable) {
          if (!variable.defaultValue) variable.defaultValue = '';
          const value = `{{${variable.key} | default: "${variable.defaultValue}"}}`;
          const defaultValue =
            variable.defaultValue && variable.defaultValue !== ''
              ? ` | ${variable.defaultValue}`
              : '';
          // add a title with a value in scope of PUB-2940
          editor.html.insert(
            `<span 
              ${Firefox || Safari ? 'tabindex="-1"' : ''}
              contenteditable="false"
              class="variable ${styles.variable} fr-deletable"
              data-liquid-unique-id="${liquidId}"
              data-liquid="${key}" 
              data-default-value="${variable.defaultValue}"
              data-content="{&nbsp;${variable.key}${defaultValue}&nbsp;}"
            ><span class="hidden ${styles.hidden}">${value}</span></span>`,
            true
          );
          setCursor(editor, liquidId, 'insert');
          setColor(editor, liquidId);
        }
      }

      // The start point for your plugin.
      /* eslint-disable no-underscore-dangle */
      function _init() {
        setVariables(liquidVariables || []);
        fixCursedCursor(editor);
        fixFormatting(editor);
      }

      // Expose public methods. If _init is not public then the plugin won't be initialized.
      // Public method can be accessed through the editor API:
      // editor('myPlugin.publicMethod');
      // but first need to be added to froala.d.ts
      return {
        _init,
        insertVariable,
      };
    };

    FroalaEditor.RegisterCommand('liquid', {
      type: 'dropdown',
      title: 'Variables',
      html() {
        return "<ul class='fr-dropdown-list'></ul>";
      },
      callback(_buttonName: string, val: string) {
        if (liquidVariables) {
          const allVariables = liquidVariables;
          const value = allVariables.find((v: LiquidVariable) => v.key === val);
          if (value && this.liquid) {
            this.liquid.insertVariable(val, value.value);
          }
        }
      },
      refreshOnShow(elem, container) {
        const choices = container.get(0).querySelector('ul.fr-dropdown-list');
        choices.innerHTML = '';

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        choices.addEventListener('mouseover', (event: any) => {
          const expandLi = event.target.closest('[data-cmd="expand"]');
          if (expandLi) {
            const fileName = expandLi.dataset.param;
            const secondaryDropdown = document.querySelector(
              `.fr-dropdown-list-${fileName}`
            );
            if (secondaryDropdown) {
              const rect = expandLi.getBoundingClientRect();
              const secondaryDropdownStyle = {
                display: 'block',
                left: `${rect.right}px`,
                top: `${rect.top}px`,
                zIndex: '9999',
                backgroundColor: 'white',
                listStyle: 'none',
                cursor: 'pointer',
                padding: '8px 0px',
                boxShadow:
                  '0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
              };

              Object.assign(
                (secondaryDropdown as HTMLElement).style,
                secondaryDropdownStyle
              );
            }

            let isMouseInSecondaryDropdown = false;
            let timeoutId: ReturnType<typeof setTimeout>;

            expandLi.addEventListener('mouseleave', () => {
              timeoutId = setTimeout(() => {
                if (!isMouseInSecondaryDropdown) {
                  (secondaryDropdown as HTMLElement).style.display = 'none';
                }
              }, 1);
            });

            (secondaryDropdown as HTMLElement).addEventListener(
              'mouseenter',
              () => {
                isMouseInSecondaryDropdown = true;
              }
            );

            (secondaryDropdown as HTMLElement).addEventListener(
              'mouseleave',
              () => {
                isMouseInSecondaryDropdown = false;
                (secondaryDropdown as HTMLElement).style.display = 'none';
              }
            );

            (secondaryDropdown as HTMLElement).addEventListener(
              'mouseenter',
              () => {
                clearTimeout(timeoutId);
              }
            );
          }
        });

        const groupedVariables = variablesRef.current?.reduce<{
          [key: string]: LiquidVariable[];
        }>((acc, variable) => {
          const key = variable.fileName || 'No file';
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(variable);
          return acc;
        }, {});

        if (personalizedFieldsFlagV2?.value) {
          const systemVariables = variablesRef.current?.filter(
            (variable: LiquidVariable) =>
              !variable.fileName && variable.key !== 'federated_identifier'
          );

          if (
            systemVariables?.length === 0 &&
            groupedVariables &&
            Object.keys(groupedVariables).length === 0
          ) {
            choices.innerHTML += `<li class="fr-variable">
            <a class="${styles.frTitle}" data-param="system-variables">
                <div style="display: flex; justify-content: space-between">
                  <span>None Available</span> 
                </div>
            </a>
          </li>`;
          } else {
            choices.innerHTML += `<li class="fr-file fr-expand-system-variables">
            <a class="${styles.frTitle} fr-command fr-title" data-cmd="expand" data-param="system-variables">
                <div style="display: flex; justify-content: space-between">
                  <span>User Attributes</span> 
                  <span style='position: absolute; left: 84%; font-size: 20px;'>&rsaquo;</span>
                </div>
            </a>
          </li>`;
          }

          let secondaryDropdown = document.querySelector(
            `.fr-dropdown-list-system-variables`
          );

          if (!secondaryDropdown) {
            secondaryDropdown = document.createElement('ul');
            secondaryDropdown.className = `fr-dropdown-list fr-dropdown-list-system-variables`;
            (secondaryDropdown as HTMLElement).style.display = 'none';
            (secondaryDropdown as HTMLElement).style.position = 'absolute';
            (secondaryDropdown as HTMLElement).style.overflowY = 'auto';
            (secondaryDropdown as HTMLElement).style.maxHeight = '50vh';
          } else {
            secondaryDropdown.innerHTML = '';
          }

          const secondaryOptionsHtml =
            systemVariables?.length === 0
              ? `<li class="fr-variable">
            <a class=" ${styles.frTitle}" data-param="none-available">
              <div>None Available</div>
            </a>
          </li>`
              : systemVariables
                  ?.sort((a: LiquidVariable, b: LiquidVariable) => {
                    const keyA = a.key.replace('cstm_', '');
                    const keyB = b.key.replace('cstm_', '');

                    return keyA.localeCompare(keyB);
                  })
                  ?.map((variable: LiquidVariable) => {
                    let variableName = variable.name;
                    if (isDuplicateStandard(variable.key)) {
                      variableName = formatAttributeDisplayName(variable.key);

                      return `<li class="fr-variable">
                <a class=" ${styles.frTitle} fr-command fr-title" data-cmd="liquid" data-param="${variable.key}">
                  <div style="display: flex">
                    <span>${variableName}</span>
                    <span class="${styles.customLabel}">(custom)</span>
                  </div>
                </a>
              </li>`;
                    }
                    if (isCustom(variable.key)) {
                      variableName = formatAttributeDisplayName(variable.key);
                    }
                    return `<li class="fr-variable">
              <a class=" ${styles.frTitle} fr-command fr-title" data-cmd="liquid" data-param="${variable.key}">
                <div>${variableName}</div>
              </a>
            </li>`;
                  })
                  .join('');

          (secondaryDropdown as HTMLElement).innerHTML += secondaryOptionsHtml;

          const secondaryOptions = secondaryDropdown.querySelectorAll(
            'li.fr-variable a'
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          secondaryOptions.forEach((option: any) => {
            option.addEventListener('mousedown', (event: MouseEvent) => {
              const { param } = (event.currentTarget as HTMLElement).dataset;
              if (liquidVariables) {
                const allVariables = liquidVariables;
                const value = allVariables.find(
                  (v: LiquidVariable) => v.key === param
                );
                if (param && value && this.liquid) {
                  this.liquid.insertVariable(param, value.value);
                }
              }
              (secondaryDropdown as HTMLElement).style.display = 'none';
            });
          });

          document.body.appendChild(secondaryDropdown);
        } else {
          const primaryOptionsHtml = variablesRef.current
            ?.filter(
              (variable: LiquidVariable) =>
                !variable.fileName && variable.key !== 'federated_identifier'
            )
            .filter((variable: LiquidVariable) => {
              if (journey) {
                return ['first_name', 'last_name'].includes(variable.key);
              }
              return true;
            })
            .map((variable: LiquidVariable) => {
              if (!variable.legacy) {
                return `
                  <li class="fr-variable">
                    <a class=" ${styles.frTitle} fr-command fr-title" data-cmd="liquid" data-param1="${variable.key}">
                      <div>${variable.name}</div>
                    </a>
                  </li>`;
              }
              return '';
            })
            .join('');

          choices.innerHTML += primaryOptionsHtml;
        }

        if (groupedVariables && configureSetPersonalizedFields)
          Object.keys(groupedVariables).forEach((fileName) => {
            if (fileName === 'No file') return;
            const sanitizedFileName = fileName.replace(/[^\w]/g, '_');

            choices.innerHTML += `<li class="fr-file fr-expand-${sanitizedFileName}">
            <a class="${styles.frTitle} fr-command fr-title" data-cmd="expand" data-param="${sanitizedFileName}">
                <div style="display: flex; justify-content: space-between">
                  <span>${sanitizedFileName}</span> 
                  <span style='position: absolute; left: 84%; font-size: 20px;'>&rsaquo;</span>
                </div>
            </a>
          </li>`;

            let secondaryDropdown = document.querySelector(
              `.fr-dropdown-list-${sanitizedFileName}`
            );

            if (!secondaryDropdown) {
              secondaryDropdown = document.createElement('ul');
              secondaryDropdown.className = `fr-dropdown-list fr-dropdown-list-${sanitizedFileName}`;
              (secondaryDropdown as HTMLElement).style.display = 'none';
              (secondaryDropdown as HTMLElement).style.position = 'absolute';
            } else {
              secondaryDropdown.innerHTML = '';
            }

            const secondaryOptionsHtml = groupedVariables[fileName]
              .map((variable: LiquidVariable) => {
                return `<li class="fr-variable">
                <a class=" ${styles.frTitle} fr-command fr-title" data-cmd="liquid" data-param="${variable.key}">
                  <div>${variable.name}</div>
                </a>
              </li>`;
              })
              .join('');

            (secondaryDropdown as HTMLElement).innerHTML += secondaryOptionsHtml;

            const secondaryOptions = secondaryDropdown.querySelectorAll(
              'li.fr-variable a'
            );

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            secondaryOptions.forEach((option: any) => {
              option.addEventListener('mousedown', (event: MouseEvent) => {
                const { param } = (event.currentTarget as HTMLElement).dataset;
                if (liquidVariables) {
                  const allVariables = liquidVariables;
                  const value = allVariables.find(
                    (v: LiquidVariable) => v.key === param
                  );
                  if (param && value && this.liquid) {
                    this.liquid.insertVariable(param, value.value);
                  }
                }
                (secondaryDropdown as HTMLElement).style.display = 'none';
              });
            });

            document.body.appendChild(secondaryDropdown);
          });
      },
    });
  }

  return {
    events: {
      initializationDelayed(this: FroalaEditor) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const ed = this;
        ed.$oel.on('triggerFroalaContentChanged', (event: Event) => {
          // Froala doesn't register the change if html content is changed with a script rather than typed
          // we need to force it to register the change
          // passing the editor instance (editor.current) from InlineEditor won't work
          // so we need this dirty hack with an event to make it register the change
          ed.opts.events.contentChanged?.apply(ed);
          setCursor(ed, (event as CustomEvent).detail);
        });
      },
    },
  };
}
