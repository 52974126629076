import React from 'react';
import { Box, FormModal } from 'DesignSystem/Components';
import { FieldInput, Radio } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import {
  FetchedCaption,
  TranscriptionJob,
  TranslationJob,
} from 'services/api-captions';
import styles from '../video.module.css';
import { useCaptionSettings } from '../../hooks/useCaptionSettings';
import { UploadedCaptionsForm } from './UploadedCaptionsForm';
import {
  useCaptionsJobs,
  useTranscribeVideo,
  useTranslateVideo,
} from '../../hooks/useCaptionsJobs';
import { CaptionsTranslation } from './CaptionsTranslation';

export type CaptionSettingsModalProps = {
  onClose: () => void;
  onFileSelect: (captionFile: File) => void;
  onCaptionRemove: (url: string) => void;
  isCaptionsUploading: boolean;
  currentCaption?: FetchedCaption;
  videoId?: number;
  isDesignAsset: boolean;
};

export enum CaptionsType {
  GENERATE = 'generate',
  UPLOAD = 'upload',
}

export const CaptionSettings: React.FC<CaptionSettingsModalProps> = ({
  onClose,
  onFileSelect,
  onCaptionRemove,
  isCaptionsUploading,
  currentCaption,
  videoId,
  isDesignAsset,
}) => {
  const { id: programId } = useProgram();

  const {
    onFormSubmit: onUpload,
    captionText,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    isDisabled,
    validationErrors,
  } = useCaptionSettings({
    onFileSelect,
    onCaptionRemove,
    isCaptionsUploading,
    currentCaption,
  });
  const [captionsType, setCaptionsType] = React.useState<CaptionsType>(
    CaptionsType.UPLOAD
  );
  const [autoTranslateCaptions, setAutoTranslateCaptions] = React.useState(
    false
  );

  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    []
  );

  const {
    isCaptionTranscriptionEnabled,
    isCaptionTranslationEnabled,
    isLoadingFeatureFlags,
  } = useCaptionsJobs();
  const selectUpload = () => {
    setCaptionsType(CaptionsType.UPLOAD);
    setAutoTranslateCaptions(false);
    setSelectedLanguages([]);
  };
  const selectGenerate = () => setCaptionsType(CaptionsType.GENERATE);
  const { setTranscriptionJob, setTranslationJob } = useCaptionsJobs();

  const handleStartTranscription = (job: TranscriptionJob) => {
    setTranscriptionJob(job);
  };

  const handleStartTranslation = (job: TranslationJob) => {
    setTranslationJob(job);
  };

  const toggleAutoTranslateCaptions = React.useCallback(() => {
    if (autoTranslateCaptions) {
      setSelectedLanguages([]);
    }

    setAutoTranslateCaptions(!autoTranslateCaptions);
  }, [autoTranslateCaptions]);

  const { startTranscription } = useTranscribeVideo({
    onSuccess: handleStartTranscription,
  });

  const { startTranslation } = useTranslateVideo({
    onSuccess: handleStartTranslation,
    languages: selectedLanguages,
  });

  React.useEffect(() => {
    if (isCaptionTranscriptionEnabled) {
      setCaptionsType(CaptionsType.GENERATE);
    }
  }, [isCaptionTranscriptionEnabled]);

  if (isLoadingFeatureFlags) return null;
  return (
    <FormModal
      entityText={
        isCaptionTranscriptionEnabled && !captionText
          ? 'Add Captions'
          : 'Caption Settings'
      }
      actionText=""
      description={
        isCaptionTranscriptionEnabled
          ? 'Captions can be edited, translated, and downloaded after processing.'
          : ''
      }
      submitLabel="Continue"
      onSubmit={() => {
        if (captionsType === CaptionsType.GENERATE) {
          startTranscription({
            programId,
            videoId,
            isDesignAsset,
          });

          if (selectedLanguages.length > 0) {
            startTranslation({
              programId,
              videoId,
              isDesignAsset,
              languages: selectedLanguages,
            });
          }
        } else {
          onUpload();
        }
        onClose();
      }}
      onCancel={onClose}
      disabled={
        captionsType === CaptionsType.UPLOAD
          ? isDisabled
          : autoTranslateCaptions && selectedLanguages.length === 0
      }
    >
      <Box
        className={
          isCaptionTranscriptionEnabled
            ? styles.addCaptions
            : styles.captionsSettings
        }
      >
        {isCaptionTranscriptionEnabled && (
          <>
            {!captionText && (
              <>
                <Box style={{ display: 'flex' }}>
                  <Radio
                    type="radio"
                    label={
                      <Box margin={[0, 0, 0, -8]}>
                        <Text.Body>Automatically generate captions</Text.Body>
                      </Box>
                    }
                    checked={captionsType === CaptionsType.GENERATE}
                    onSelect={selectGenerate}
                  />
                </Box>
                {isCaptionTranslationEnabled &&
                  captionsType === CaptionsType.GENERATE && (
                    <>
                      <Box padding={[16, 0, 0, 32]} style={{ display: 'flex' }}>
                        <Radio
                          label={
                            <Box margin={[0, 0, 0, -8]}>
                              <Text.Body>
                                Auto-translate these captions
                              </Text.Body>
                            </Box>
                          }
                          checked={autoTranslateCaptions}
                          onChange={toggleAutoTranslateCaptions}
                        />
                      </Box>
                      {autoTranslateCaptions && (
                        <Box padding={[0, 0, 0, 32]}>
                          <CaptionsTranslation
                            disabled={!autoTranslateCaptions}
                            selectedLanguages={selectedLanguages}
                            setSelectedLanguages={setSelectedLanguages}
                          />
                        </Box>
                      )}
                    </>
                  )}

                <Box
                  padding={[16, 0]}
                  className={styles.uploadCaptionsWrapper}
                  style={{ display: 'flex' }}
                >
                  <Radio
                    type="radio"
                    label={
                      <Flex className={styles.uploadCaptionsLabel} column start>
                        <Text.Body>Upload your own caption file(s)</Text.Body>
                        <Text.Caption>
                          You can select multiple files at once for upload
                        </Text.Caption>
                      </Flex>
                    }
                    checked={captionsType === CaptionsType.UPLOAD}
                    onSelect={selectUpload}
                  />
                </Box>
              </>
            )}
            {(captionsType === CaptionsType.UPLOAD || captionText) && (
              <UploadedCaptionsForm
                captionText={captionText}
                validationErrors={validationErrors}
                onReplaceClick={onReplaceClick}
                onRemoveClick={onRemoveClick}
                onUploadClick={onUploadClick}
                fileInput={fileInput}
                errorMessage={errorMessage}
              />
            )}
          </>
        )}
        {!isCaptionTranscriptionEnabled && (
          <FieldInput>
            <UploadedCaptionsForm
              captionText={captionText}
              validationErrors={validationErrors}
              onReplaceClick={onReplaceClick}
              onRemoveClick={onRemoveClick}
              onUploadClick={onUploadClick}
              fileInput={fileInput}
              errorMessage={errorMessage}
            />
          </FieldInput>
        )}
      </Box>
    </FormModal>
  );
};
