import React, { useState } from 'react';
import { useJourneyState } from 'contexts/journeys/journey';
import { useDesignContext } from 'contexts/design';
import { EmailChannel } from 'models/journeys/journey';
import { SendTestCampaignModalComponent } from 'App/Program/Editors/Publisher/NewReview/ContentPreviewSection/SendTestCampaignModal';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useSendTestEmail } from 'hooks/useSendTestEmail';
import { User } from 'models/user';

export const SendTestJourneyContentModal: React.FC<{
  toggleShow: () => void;
}> = ({ toggleShow }) => {
  const { activeStep } = useJourneyState();
  const { design } = useDesignContext();
  const isCommunicationStep = activeStep?.type === 'communication';
  const emailCommunication = isCommunicationStep
    ? (activeStep.channels.find((c) => c.name === 'email') as EmailChannel)
    : undefined;
  const [localEmailText, setLocalEmailText] = useState(
    isCommunicationStep ? emailCommunication?.subject || '' : ''
  );
  const [localEmailPreview, setLocalEmailPreview] = useState(
    isCommunicationStep ? emailCommunication?.previewText || '' : ''
  );
  const { id: programId } = useProgram();
  const [users, setUsers] = React.useState<User[]>([]);
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );
  const { sendEmailRequest } = useSendTestEmail({
    subject: localEmailText,
    previewText: localEmailPreview,
    design,
    userIds: users.map(({ id }) => id),
    preferOutlook365,
    type: 'journey',
  });

  if (!activeStep || !isCommunicationStep) {
    return null;
  }

  return (
    <SendTestCampaignModalComponent
      localEmailText={localEmailText}
      setLocalEmailText={setLocalEmailText}
      localEmailPreview={localEmailPreview}
      setLocalEmailPreview={setLocalEmailPreview}
      toggleShow={toggleShow}
      hasPushChannel={false}
      hasEmailChannel
      users={users}
      setUsers={setUsers}
      sendEmailRequest={sendEmailRequest}
    />
  );
};
