import React from 'react';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useDesignContext } from 'contexts/design';
import { languageDisplayName } from 'utility/language';
import { FetchedCaption } from 'services/api-captions';
import { CaptionPreview } from './CaptionPreview';

export const List: React.FC<{
  captions: FetchedCaption[];
  locale?: string;
  onEdit: (index: number) => void;
  onRemove: (index: number) => void;
  filename: string;
  videoId?: number;
}> = ({ captions, onEdit, onRemove, filename, videoId, locale }) => {
  const { id: programId } = useProgram();
  const { active: isDesignAsset } = useDesignContext();

  const captionTranscriptionEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CaptionTranscription'
  ).data?.value;

  const sortCaptions = (sortableCaptions: FetchedCaption[]) => {
    const languageDisplay = new Intl.DisplayNames(undefined, {
      type: 'language',
      languageDisplay: 'standard',
    });

    return sortableCaptions.sort((a: FetchedCaption, b: FetchedCaption) =>
      languageDisplayName(a.language, languageDisplay).localeCompare(
        languageDisplayName(b.language, languageDisplay)
      )
    );
  };

  return (
    <>
      <ul style={{ listStyleType: 'none' }}>
        {sortCaptions(captions).map((caption, index) => (
          <li key={caption.url} style={{ margin: '3px 0' }}>
            <CaptionPreview
              locale={locale}
              canDownload={captionTranscriptionEnabled}
              filename={filename}
              onEdit={() => onEdit(index)}
              onRemove={() => onRemove(index)}
              caption={caption}
              videoId={videoId}
              isDesignAsset={isDesignAsset}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
