import React from 'react';
import Select, {
  OptionsType,
  ValueType,
  components,
  OptionProps,
  createFilter,
} from 'react-select';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useProgramIdState } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { useUsersInfiniteQuery } from 'hooks/user';
import { fullName, User } from 'models/user';
import { DefaultAvatar } from 'shared/icons';
import style from './user-select.module.css';

type Option = {
  value: number;
  label: string;
};

type PropsType = {
  initialValue?: User | Array<User>;
  onChange: (selection: User | Array<User> | undefined) => void;
  placeholder?: string;
  filters?: {
    withoutAliasedUsers?: boolean;
    withoutHiddenOnProgram?: boolean;
  };
  statuses?: string[];
  isMulti?: boolean;
  showOptionAvatar?: boolean;
  error?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  allowEmailSearch?: boolean;
};

function mapUserToOption(
  user: User,
  useDisplayName?: boolean,
  allowEmailSearch?: boolean
) {
  let label = fullName(user);
  if (useDisplayName) {
    label =
      user.lastName || user.firstName ? fullName(user) : user.displayName || '';
  }

  return allowEmailSearch
    ? {
        email: user.email,
        value: user.id,
        label,
        avatarUrl: user.avatarUrl,
      }
    : {
        value: user.id,
        label,
        avatarUrl: user.avatarUrl,
      };
}

function filter(collection: Array<User>, options: ValueType<Option>) {
  const ids = (options as OptionsType<Option>).map((o) => o.value);
  return collection.filter((u) => ids.includes(u.id));
}

function find(collection: Array<User>, option: ValueType<Option>) {
  return collection.find((u) => u.id === (option as Option).value);
}

const optionsStyle = () => {
  const opstyle = {
    label: 'option',
    backgroundColor: 'transparent',
    color: 'inherit',
    cursor: 'default',
    display: 'block',
    fontSize: 'inherit',
    padding: '8px 12px',
    width: '100%',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userSelect: 'none' as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    boxSizing: 'border-box' as any,
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    ':active': {
      backgroundColor: 'var(--color-gray05)',
    },
  };
  return opstyle;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectOption: React.FC<OptionProps<any>> = (props) => {
  const { data: user } = props;
  return (
    <div className={style.option}>
      <div className={style.optionAvii}>
        {user?.avatarUrl ? (
          <img src={user?.avatarUrl} alt="" />
        ) : (
          <DefaultAvatar />
        )}
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props} />
    </div>
  );
};

export const UserSelect: React.FC<PropsType> = ({
  initialValue,
  onChange,
  placeholder = '',
  filters = {},
  statuses,
  showOptionAvatar = false,
  isMulti = false,
  error,
  errorMessage,
  onBlur,
  allowEmailSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [programId] = useProgramIdState();
  const search = useDebounce(searchTerm);

  const defaultValue = React.useMemo(() => {
    if (!initialValue) return null;

    return Array.isArray(initialValue)
      ? initialValue.map((u: User) =>
          mapUserToOption(u, showOptionAvatar, allowEmailSearch)
        )
      : mapUserToOption(initialValue, showOptionAvatar, allowEmailSearch);
  }, [initialValue, showOptionAvatar, allowEmailSearch]);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
  } = useUsersInfiniteQuery({ programId, search, filters, statuses });

  const options = React.useMemo(() => {
    if (data)
      return data.map((u: User) =>
        mapUserToOption(u, showOptionAvatar, allowEmailSearch)
      );
    return [];
  }, [data, showOptionAvatar, allowEmailSearch]);

  const handleChange = React.useCallback(
    (option: ValueType<Option>) => {
      let selectedValue: User | Array<User> | undefined;

      if (!option) {
        onChange(isMulti ? [] : undefined);
        return;
      }

      if (Array.isArray(option)) {
        selectedValue = filter(data, option);
      } else {
        selectedValue = find(data, option);
      }

      if (selectedValue) onChange(selectedValue);
    },
    [data, isMulti, onChange]
  );

  const onScroll = React.useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage]);

  const customFilterOption = allowEmailSearch
    ? createFilter({
        stringify: (option) => `${option.label} ${option.data.email}`,
      })
    : createFilter({});

  return (
    <>
      {showOptionAvatar ? (
        <Select
          defaultValue={defaultValue}
          filterOption={customFilterOption}
          onMenuScrollToBottom={onScroll}
          isMulti={isMulti}
          isLoading={isLoading}
          options={options}
          onChange={handleChange}
          onInputChange={(value: string) => setSearchTerm(value)}
          placeholder={placeholder}
          onBlur={onBlur}
          components={{ Option: SelectOption }}
          styles={{
            option: optionsStyle,
            control: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow:
                error && state.isFocused
                  ? '0 0 0 0.2rem rgba(120, 194, 173, 0.25)'
                  : baseStyles.boxShadow,
              borderColor: error ? '#dd1d1d' : '#b2b1ba',
              '&:hover': {
                borderColor: error ? '#dd1d1d' : '#b2b1ba',
              },
            }),
          }}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          filterOption={customFilterOption}
          onMenuScrollToBottom={onScroll}
          isMulti={isMulti}
          isLoading={isLoading}
          options={options}
          onChange={handleChange}
          onInputChange={(value: string) => setSearchTerm(value)}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      )}
      <>
        {error && errorMessage && (
          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: '#DD1D1D',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon name="WarningTriangle" size={16} />
            <div style={{ padding: '5px 10px' }}>{errorMessage}</div>
          </div>
        )}
      </>
    </>
  );
};
