export enum VideoEditorMode {
  Upload = 'upload',
  External = 'external',
}

export enum VideoProcessingStep {
  Idle,
  Uploading,
  Transcoding,
  Complete,
  Error,
}

export type Caption = {
  id: number;
  invalidFormat: boolean;
  programId: number;
  locale?: string;
  url?: string;
  generated: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type Job = {
  id: number;
  videoId: number;
  videoType: string;
  jobPercentComplete: number;
  transcodeUrl?: string;
  filename?: string;
  currentStatus: string;
  transcodeJobId?: string;
  uploadedVideoUrl?: string;
  executionAction?: string;
  generatedThumbnail?: string;
  startedAt?: Date;
  completedAt?: Date;
};
export type CaptionLocaleUrl = {
  id: number;
  url: string;
  language: string;
  // TODO:
  // This field is consumed by the original embedded video player code in bossanova, but it is
  // not populated by ContentVideo#content_urls in pony.  There also does not appear to be a
  // column for it in migranova.  I have it here to be consistent with what was in boss, but
  // passing it to the video player is likely a no-op.
  label?: string;
  generated: boolean;
};

export type Video = {
  altText?: string;
  caption?: string;
  captionPlaylists?: Array<CaptionLocaleUrl>;
  captionUrls?: Array<CaptionLocaleUrl>;
  captions?: Array<Caption>;
  customPreviewImageUrl?: string;
  durationInSeconds?: number;
  embedHtml: string;
  filename: string;
  framerate?: number;
  generatedThumbnail?: string;
  height?: number;
  id: number;
  isPrivate: boolean;
  jobId?: string;
  jobPercentComplete?: number;
  jobs: Array<Job>;
  linkUrl?: string;
  locale?: string;
  mp4Url?: string;
  previewImageUrl?: string;
  programId: number;
  secureQueryParams?: string;
  sourceType: string;
  status: string;
  uploadedVideoUrl: string;
  url: string;
  uuid: string;
  width?: number;
};

export type SecureVideo = Video & SecureParam;

type SecureParam = {
  secure?: {
    queryParams: string;
  };
};

// TODO:
// There is a little bit of overlap with the uploaded video type.  Should these both derive
// from the same shared interface?
export type ExternalVideo = {
  authorName?: string;
  description?: string;
  embedHtml: string;
  embedType?: string;
  height: number;
  id: number;
  provider?: string;
  thumbnailHeight?: number;
  thumbnailUrl?: string;
  thumbnailWidth?: number;
  title?: string;
  url: string;
  width: number;
};

export type VideoProcessingStatus = {
  step: VideoProcessingStep;
  uploadPercentComplete?: number;
  transcodePercentComplete?: number;
  errorMessage?: string;
};

export type ExternalVideoStatus = {
  isLoading: boolean;
  errorMessage?: string;
};

export const DEFAULT_MAX_FILE_SIZE = 500 * 1024 * 1024;

export const ALLOWED_MIME_TYPES = [
  'video/avi',
  'video/msvideo',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/mpeg',
  'video/mp4',
  'video/quicktime',
  'video/webm',
];
