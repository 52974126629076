import qs from 'qs';
import { CaptionLocaleUrl, Video } from 'models/video';
import snakecaseKeys from 'snakecase-keys';
import { request } from './api-shared';
import { fqApiUrl } from './common';
import { headers, parseResponse } from './helpers/json-api';
import { UploadProgress, uploadToS3 } from './helpers/upload-to-s3';

export type FetchedCaption = CaptionLocaleUrl & {
  text: string;
};

export type TranscribeProps = {
  programId: number;
  isDesignAsset?: boolean;
  videoId?: number;
};

export type TranslationLanguagesProps = {
  programId: number;
};

export type TranslateProps = {
  programId: number;
  videoId?: number;
  languages: string[];
  isDesignAsset?: boolean;
  sourceCaptionId?: number;
};

export type TranscriptionJob = {
  id: number;
  status: 'processing' | 'completed' | 'failed';
  caption?: FetchedCaption;
};

export type TranslationJob = {
  id: number;
  status: 'processing' | 'completed' | 'failed';
  captions?: FetchedCaption[];
};

export async function transcribeVideo({
  programId,
  isDesignAsset,
  videoId,
}: TranscribeProps): Promise<TranscriptionJob> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/transcribe`
    : `samba/programs/${programId}/videos/${videoId}/transcribe`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    headers,
  });

  const result = await parseResponse<TranscriptionJob>(response);
  return {
    ...result,
    caption: result.caption && (await resolveCaptionText(result.caption)),
  };
}

export async function translateVideo({
  programId,
  isDesignAsset,
  videoId,
  languages,
  sourceCaptionId,
}: TranslateProps): Promise<TranslationJob> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/translate`
    : `samba/programs/${programId}/videos/${videoId}/translate`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    headers,
    body: JSON.stringify(
      snakecaseKeys({
        locales: languages,
        sourceCaptionId,
      })
    ),
  });

  const result = await parseResponse<TranslationJob>(response);
  return result;
}

export type CaptionsJobs = {
  transcription: TranscriptionJob;
  translation: TranslationJob;
};

export async function fetchCaptionsJobs({
  programId,
  isDesignAsset,
  videoId,
}: TranscribeProps): Promise<CaptionsJobs> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/captions_jobs`
    : `samba/programs/${programId}/videos/${videoId}/captions_jobs`;

  const response = await request(fqApiUrl(requestUrl));

  const result = await parseResponse<CaptionsJobs>(response);
  const translationResults = result.translation?.captions
    ? await Promise.all(
        result.translation?.captions?.map((caption) =>
          resolveCaptionText(caption)
        )
      )
    : [];

  return {
    ...result,
    transcription: {
      ...result.transcription,
      caption:
        result.transcription.caption &&
        (await resolveCaptionText(result.transcription.caption)),
    },
    translation: {
      ...result.translation,
      captions: translationResults.map((text, i) => ({
        ...(result.translation.captions as FetchedCaption[])[i],
        text: text.text,
      })),
    },
  };
}

const resolveCaptionText = async (
  caption: Omit<FetchedCaption, 'text'>
): Promise<FetchedCaption> => {
  const response = await fetch(caption.url, { credentials: 'include' });
  return {
    ...caption,
    text: response.ok ? await response.text() : '',
  };
};

export async function fetchCaptionsUploadUrl(
  programId: number,
  filename: string
): Promise<string> {
  const query = qs.stringify({ filename });
  const response = await request(
    fqApiUrl(`samba/programs/${programId}/videos/caption_upload_url?${query}`)
  );

  const result = await parseResponse<{ url: string }>(response);
  return result.url;
}

export type UploadCaptionOptions = {
  programId: number;
  isDesignAsset?: boolean;
  videoId: number;
  file: File;
  onUploadProgress?: (progress: UploadProgress) => void;
};

export async function uploadCaptions({
  file,
  programId,
  isDesignAsset,
  videoId,
  onUploadProgress,
}: UploadCaptionOptions): Promise<Video> {
  const filename = file.name.replace(/[^\w-_.]/g, '');
  const url = await fetchCaptionsUploadUrl(programId, filename);

  await uploadToS3({ url, file, onUploadProgress });

  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}`
    : `samba/programs/${programId}/videos/${videoId}`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'PUT',
    body: JSON.stringify({ captions: [{ url }] }),
    headers,
  });

  return parseResponse<Video>(response);
}

export type RemoveCaptionsOptions = {
  programId: number;
  isDesignAsset?: boolean;
  videoId: number;
  url?: string;
};

type RemoveCaptionsBodyParams = {
  remove_caption_by_url?: string;
  remove_all_captions?: boolean;
};

export async function removeCaptions({
  programId,
  isDesignAsset,
  videoId,
  url,
}: RemoveCaptionsOptions): Promise<Video> {
  const body: RemoveCaptionsBodyParams = {};
  if (url) {
    body.remove_caption_by_url = url;
  } else {
    body.remove_all_captions = true;
  }

  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}`
    : `samba/programs/${programId}/videos/${videoId}`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'PUT',
    body: JSON.stringify(body),
    headers,
  });

  return parseResponse<Video>(response);
}

export type TranslationLanguage = {
  languageCode: string;
  languageName: string;
};

export async function fetchTranslationLanguages({
  programId,
}: TranslationLanguagesProps): Promise<Array<TranslationLanguage>> {
  const requestUrl = `samba/programs/${programId}/videos/translate/languages`;

  const response = await request(fqApiUrl(requestUrl));

  const result = await parseResponse<Array<TranslationLanguage>>(response);
  return result;
}
