import { ProgramOrAuthor } from 'hooks/useAuthorsList';
import { DateTime } from 'luxon';
import { JourneyChannel } from 'models/channel';
import {
  ComplexExpression,
  defaultComplexExpression,
  SimpleExpression,
} from 'models/expression';
import { EmailSenderAlias } from 'models/publisher/settings';
import { Brand } from 'utility-types';
import { asserts } from 'utility/asserts';
import { v4 as uuidv4 } from 'uuid';
import { getTimeZones } from '@vvo/tzdb';

export enum MetricsMode {
  members = 'members',
  engagement = 'engagement',
}

export const CHANNEL_NAMES = {
  EMAIL: 'email',
  NOTIFICATION_CENTER: 'notification_center',
  PUSH: 'push',
} as const;

export type Steps = {
  communication: CommunicationStep;
  delay: DelayStep;
  decision: DecisionStep;
  start: StartStep;
  end: EndStep;
};

export type BaseStep<T extends keyof Steps, K = BaseEdge> = {
  id: string;
  name?: string;
  type: T;
  next: K[];
};

export type StartStep = BaseStep<'start'> & {
  trigger?: Trigger;
  criterion?: ComplexExpression;
  rootStepId: string;
};

export type DelayStep = BaseStep<'delay'> & {
  unit: 's' | 'min' | 'h' | 'd' | 'w' | 'mo';
  quantity: number;
  weekdayOnly?: boolean;
};

export type DecisionStep = BaseStep<'decision', DecisionEdge>;

export type Acknowledgement = {
  label?: string;
  customLabel: string;
};

export type CommunicationStep = BaseStep<'communication'> & {
  title: string;
  designId?: number;
  channels: DeliveryChannel[];
  author: ProgramOrAuthor | undefined;
  approved?: boolean;
  /**
   * Represents the currently checked channels in the CommunicationStep
   * configuration UI. This is not persisted to the backend, but is used
   * to track the state of the UI without mutating the channels array.
   */
  selectedChannels: DeliveryChannel['name'][];
  acknowledgement?: Acknowledgement;
  /**
   * Smart channel selection for the communication on the step.
   *
   * - `optimize`: The planner will determine the best channel to send the communication to
   *   from the selected channels.
   * - `all`: The journey will send the communication to all selected channels.
   *
   * The value should be `undefined` if the channel delivery is not configurable.
   */
  channelDelivery: ('optimize' | 'all') | undefined;
};

export type EndStep = BaseStep<'end'>;

export type BaseEdge = {
  targetId: string;
};

export type DecisionEdge = BaseEdge & {
  label: string;
  order: number;
  isDefault?: boolean;
  criterion?: ComplexExpression;
};

type BaseTrigger<T extends Trigger['type']> = {
  type: T;
};

export type ImmediateTrigger = BaseTrigger<'immediate'>;

export type ScheduledTrigger = BaseTrigger<'scheduled'> & {
  date?: DateTime;
};

export type EventTrigger = BaseTrigger<'event'> & {
  event: string;
};

export const timeZones = getTimeZones({ includeUtc: true }).map((tz) => ({
  ...tz,
  // label/value to use with GenericSelect component
  label: tz.name.replaceAll('_', ' '),
  value: tz.name,
}));

type TimeZoneWithLabel = typeof timeZones[number];
export function timeZoneOrUTC(timezoneName: string): TimeZoneWithLabel {
  const timezone = timeZones.find(
    (tz) => tz.name === timezoneName || tz.group.includes(timezoneName)
  );

  if (!timezone) {
    asserts(timezoneName !== 'UTC', 'UTC timezone should always be found');
    return timeZoneOrUTC('UTC');
  }

  return timezone;
}

export const localeTimezone: string = timeZoneOrUTC(
  Intl.DateTimeFormat().resolvedOptions().timeZone
).name;

/**
 * The default time for a recurring trigger.
 */
export function recurringTriggerTime(): TimeOfDay {
  const nowPlusOne = DateTime.now().plus({ hours: 1 });
  return timeOfDay(nowPlusOne.hour, nowPlusOne.minute);
}

/**
 * TimeOfDay represents a time of day in hours and minutes using a 24-hour clock.
 */
export type TimeOfDay = Brand<
  {
    hour: number;
    minute: number;
  },
  'TimeOfDay'
>;

export function timeOfDay(hour: number, minute: number): TimeOfDay {
  asserts(Number.isInteger(hour), 'hour must be an integer');
  asserts(Number.isInteger(minute), 'minute must be an integer');
  asserts(hour >= 0 && hour < 24, 'hour must be between 0 and 23');
  asserts(minute >= 0 && minute < 60, 'minute must be between 0 and 59');
  return { hour, minute } as TimeOfDay;
}

/**
 * A recurring trigger is a Journey initiation trigger that executes at
 * defined frequencies.
 */
export type RecurringTrigger = BaseTrigger<'recurring'> & {
  /**
   * The frequency of the recurring trigger execution.
   */
  frequency: 'daily';
  /**
   * The trigger criterion that determines which Audience members will be eligible
   * to enter the journey at the time of execution.
   */
  triggerCriterion?: SimpleExpression;
  /**
   * Time of day the recurring trigger will execute.
   */
  timeOfDay: TimeOfDay;
  /**
   * The selected timezone of the recurring trigger. This is the timezone
   * that the time of day will be executed in.
   *
   * The timezone is in the IANA timezone format.
   * @example 'America/New_York'
   */
  timeZone: string;
};

export type Triggers = {
  immediate: ImmediateTrigger;
  scheduled: ScheduledTrigger;
  event: EventTrigger;
  recurring: RecurringTrigger;
};

export type Trigger = Triggers[keyof Triggers];

export type DeliveryChannels = {
  email: EmailChannel;
  notification_center: NotificationCenterChannel;
  push: PushChannel;
};

export type DeliveryChannel = DeliveryChannels[keyof DeliveryChannels];

type ChannelData<T extends JourneyChannel> = {
  name: T;
};

export type PushChannel = ChannelData<'push'> & {
  text: string;
};

export type NotificationCenterChannel = ChannelData<'notification_center'> & {
  text: string;
  markAsImportant: boolean;
};

export type EmailChannel = ChannelData<'email'> & {
  previewText: string;
  subject: string;
  emailSenderAlias?: EmailSenderAlias;
  programContactAddressId?: number;
};

export const DelayStepOptions = [
  { value: 'h', label: 'Hours' },
  { value: 'd', label: 'Days' },
];

export type Step = Steps[keyof Steps];

export type JourneyState =
  | 'archived'
  | 'stopped'
  | 'active'
  | 'paused'
  | 'initial'
  | 'processing';

export type EditableJourneyState = Extract<
  JourneyState,
  'active' | 'paused' | 'initial' | 'stopped'
>;

/**
 * Represents the states a journey can be in that are editable by the user.
 * This is a subset of the JourneyState type.
 * */
export const editableJourneyStates: EditableJourneyState[] = [
  'active',
  'paused',
  'initial',
  'stopped',
];

export type ExecutionState =
  | 'stopped'
  | 'initial'
  | 'running'
  | 'paused'
  | 'verifying';

export type JourneyGraph = {
  id?: number;
  executionState: ExecutionState;
  rootStepId: string;
  steps: Step[];
  isLive: boolean;
  updatedAt?: DateTime;
  createdAt?: DateTime;
};

export type Journey = {
  id?: number;
  name: string;
  version: number;
  state: JourneyState;
  createdBy?: number;
  liveGraph?: JourneyGraph;
  draftGraph?: JourneyGraph;
  updatedAt?: DateTime;
  createdAt?: DateTime;
};

export type JourneyListItem = Pick<
  Journey,
  | 'id'
  | 'name'
  | 'state'
  | 'createdBy'
  | 'updatedAt'
  | 'createdAt'
  | 'draftGraph'
> & {
  hasDraft: boolean;
  hasLive: boolean;
  metrics?: { currentMembers: number; calculatedAt: DateTime };
};

export type JourneyTemplateListItem = Pick<
  Journey,
  'id' | 'name' | 'createdAt' | 'updatedAt'
> & { description: string; status: string };

export type AddableSteps = Omit<Steps, 'start' | 'end'>;
export type AddableStepTypes = keyof AddableSteps;
export type ConfigurableSteps = Omit<Steps, 'end'>;
export type PreviewableSteps = Pick<Steps, 'communication'>;

export const DEFAULT_EDGE_TARGET_ID = 'default';

export enum JourneyMode {
  Edit = 'edit',
  View = 'view',
}

export type MembersStepMetrics = {
  current: number;
  entered: number;
  completed: number;
};

export type EngagementStepMetrics = {
  sent: number;
  delivered: number;
  opened: number;
  clicked: number;
};

export type StepMetrics<T extends keyof Steps> = {
  stepId: string;
  stepType: T;
} & MembersStepMetrics &
  (T extends 'communication' ? EngagementStepMetrics : MembersStepMetrics);

export type JourneyExecutionMetrics = {
  journeyId: number;
  graphId: number;
  executionId: number;
  entered: number;
  current: number;
  completed: number;
  stepMetrics: Record<string, StepMetrics<keyof Steps>>;
};

/**
 * Builds the default step for a given step type and returns a tuple with
 * the default step for that type and any other steps necessary.
 *
 * For example, a DecisionStep requires two conditions, one of which will point
 * to an EndStep, requiring that the tuple return an array with the additional EndStep,
 * along with the default DecisionStep.
 *
 * Note: This would likely be easier if we stored the steps as a tree, with a head pointer,
 * rather than an array.
 *
 * @param type type of default step to build
 * @returns tuple with default step and any additional steps required
 */
export const getDefaultStep = <T extends AddableStepTypes>(
  type: T
): [AddableSteps[T], Step[]] => {
  return DEFAULT_STEP_BUILDERS[type]();
};

const buildDefaultDecisionStep = (): [DecisionStep, Step[]] => {
  const endStep: EndStep = {
    type: 'end',
    id: uuidv4(),
    next: [],
  };

  return [
    {
      id: uuidv4(),
      type: 'decision',
      name: 'Split the audience',
      next: [
        {
          targetId: DEFAULT_EDGE_TARGET_ID,
          label: 'All others',
          isDefault: true,
          order: Infinity,
        },
        {
          label: 'Split Description',
          order: 1,
          criterion: defaultComplexExpression(defaultComplexExpression()),
          targetId: endStep.id,
        },
      ]
        .sort((a, b) => a.order - b.order)
        .map((e, i) => ({
          ...e,
          order: i,
        })),
    },
    [endStep],
  ];
};

const buildDefaultCommunicationStep = (): [CommunicationStep, Step[]] => {
  const defaultChannels: EmailChannel[] = [
    {
      name: 'email',
      previewText: '',
      subject: '',
    },
  ];
  return [
    {
      id: uuidv4(),
      type: 'communication',
      next: [{ targetId: DEFAULT_EDGE_TARGET_ID }],
      title: 'Communication',
      channels: defaultChannels,
      author: undefined,
      selectedChannels: defaultChannels.map((c) => c.name),
      channelDelivery: undefined,
    },
    [],
  ];
};

const buildDefaultDelayStep = (): [DelayStep, Step[]] => {
  return [
    {
      id: uuidv4(),
      type: 'delay',
      next: [{ targetId: DEFAULT_EDGE_TARGET_ID }],
      unit: 'd',
      quantity: 1,
      weekdayOnly: false,
    },
    [],
  ];
};

const DEFAULT_STEP_BUILDERS: {
  [key in keyof AddableSteps]: () => [AddableSteps[key], Step[]];
} = {
  decision: buildDefaultDecisionStep,
  communication: buildDefaultCommunicationStep,
  delay: buildDefaultDelayStep,
};

export const isStepType = <T extends keyof Steps>(
  step: Step | unknown,
  type: T
): step is Steps[T] => {
  if ((step as Step)?.type !== type) {
    return false;
  }
  return true;
};

export const parseJourneyError = (msg: string): string => {
  const journeyErrors = JSON.parse(msg);
  const result: Array<string> = [];
  journeyErrors.errors.forEach(
    (err: Array<string | { [key: string]: Array<string> }>) => {
      const error = err[1] as { [key: string]: Array<string> };
      Object.keys(error).forEach((key) => {
        result.push(`${key} ${error[key]}`);
      }, []);
    }
  );
  return result.join(', ');
};

export const getJourneyState = (state: JourneyState): string => {
  if (state === 'active') {
    return 'Published';
  }
  if (state === 'archived') {
    return 'Archived';
  }
  return 'Draft';
};

export const UNNAMED_JOURNEY = 'Unnamed Journey';

export function isEmailChannel(
  channel: DeliveryChannel
): channel is EmailChannel {
  return channel.name === CHANNEL_NAMES.EMAIL;
}

export function isNotifitcationCenterChannel(
  channel: DeliveryChannel
): channel is NotificationCenterChannel {
  return channel.name === CHANNEL_NAMES.NOTIFICATION_CENTER;
}

export function isPushChannel(
  channel: DeliveryChannel
): channel is PushChannel {
  return channel.name === CHANNEL_NAMES.PUSH;
}
