import { RouteComponentProps, useNavigate } from '@reach/router';
import { IconButton } from '@socialchorus/shared-ui-components';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { Button, FormSection } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { BoxIntegrationData } from 'models/box-integration';
import {
  deleteRootFolderConfig,
  updateRootFolderConfig,
} from 'services/api-box-mangement';
import { PickerModal } from 'shared/Box/PickerModal';
import {
  getBoxConfigurationStatusQueryKey,
  useBoxConfigurationStatus,
} from 'shared/Box/useBoxConfigurationStatus';
import { getBoxRootFolderQueryKey } from 'shared/Box/useBoxRootFolder';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import {
  BOX_CONFIGURATION_PAGE_DESCRIPTION,
  BOX_CONFIGURATION_PAGE_TITLE,
} from '../shared/header';
import { getBoxSettingsPageBreadcrumbs } from '../shared/breadcrumbs';
import styles from './styles.module.css';

export const BoxConfigureRootFolder: React.FC<RouteComponentProps> = ({
  uri,
}) => {
  const {
    data: boxConfigurationStatus,
    isLoading,
  } = useBoxConfigurationStatus();
  const { setFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();

  const [currentFolderName, setCurrentFolderName] = useState<string>();
  const [selectedFolderId, setSelectedFolderId] = useState<string>();
  const [shouldDeleteConfig, setShouldDeleteConfig] = useState(false);
  const [showPickerModal, setShowPickerModal] = useState(false);

  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onSave = useCallback(async () => {
    setShouldConfirm(false);
    setIsSaving(true);

    try {
      if (shouldDeleteConfig) {
        await deleteRootFolderConfig(programId);
      } else if (selectedFolderId) {
        await updateRootFolderConfig(programId, selectedFolderId);
      }
      queryClient.invalidateQueries(getBoxRootFolderQueryKey(programId));
      queryClient.invalidateQueries(
        getBoxConfigurationStatusQueryKey(programId)
      );
    } catch (err) {
      setFlashMessage({
        severity: 'error',
        message:
          'There was an error updating the Box root folder configuration. Please try again.',
      });
      throw err;
    } finally {
      setIsSaving(false);
      navigate(`${uri}/..`);
    }
  }, [
    navigate,
    programId,
    queryClient,
    selectedFolderId,
    setFlashMessage,
    shouldDeleteConfig,
    uri,
  ]);

  const onSelectFolder = (folder: BoxIntegrationData) => {
    setShouldDeleteConfig(false);
    setSelectedFolderId(folder.resource_id);
    setCurrentFolderName(folder.name);
    setShowPickerModal(false);
  };

  const onSelectDeleteConfig = () => {
    setShouldDeleteConfig(true);
    setCurrentFolderName(undefined);
    setSelectedFolderId(undefined);
  };

  const hasExistingConfig =
    !!boxConfigurationStatus?.configured &&
    boxConfigurationStatus.rootFolderId !== 0;

  const displayedFolderName =
    currentFolderName ||
    (boxConfigurationStatus?.configured
      ? boxConfigurationStatus.rootFolderName
      : undefined);

  const displayedFolderId =
    selectedFolderId ||
    (boxConfigurationStatus?.configured
      ? boxConfigurationStatus.rootFolderId
      : undefined);

  const hasSelectedFolder =
    (!!selectedFolderId || hasExistingConfig) && !shouldDeleteConfig;

  return (
    <>
      <FormPage
        title={BOX_CONFIGURATION_PAGE_TITLE}
        description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
        breadcrumbs={getBoxSettingsPageBreadcrumbs('Root Folder Configuration')}
        actions={[
          {
            label: 'Save',
            onClick: () => setShouldConfirm(true),
            isLoading: isSaving,
            disabled: isLoading || !(selectedFolderId || shouldDeleteConfig),
          },
        ]}
      >
        <FormSection
          title="Root Folder"
          description="If no folder is selected, the root folder will default to the top level folder."
        >
          {isLoading ? (
            <div className={styles.Loading}>
              <LoadingSpinner />
            </div>
          ) : (
            <div className={styles.RootFolderSelectionContainer}>
              {displayedFolderName && (
                <span className={styles.RootFolderLabel}>
                  Selected:{' '}
                  {shouldDeleteConfig ? (
                    <>
                      <s>
                        <strong>{displayedFolderName}</strong>
                      </s>{' '}
                      (to be unset)
                    </>
                  ) : (
                    <>
                      <strong>{displayedFolderName}</strong> (
                      {displayedFolderId})
                    </>
                  )}
                </span>
              )}
              <div className={styles.RootFolderActionsContainer}>
                <Button
                  label={hasSelectedFolder ? 'Replace Folder' : 'Select Folder'}
                  disabled={isSaving}
                  onClick={() => setShowPickerModal(true)}
                />
                {hasExistingConfig && !shouldDeleteConfig && (
                  <IconButton
                    iconName="delete"
                    disabled={isSaving}
                    onClick={onSelectDeleteConfig}
                  />
                )}
              </div>
            </div>
          )}
        </FormSection>
      </FormPage>

      {showPickerModal && (
        <PickerModal
          entityText="root folder"
          description="Select one folder to be the root folder"
          type={['folder']}
          folderId="0"
          onSubmit={onSelectFolder}
          onCancel={() => setShowPickerModal(false)}
        />
      )}

      {shouldConfirm && (
        <ConfirmModal
          title="Replace Root Folder?"
          confirmLabel="Replace"
          onCancel={() => setShouldConfirm(false)}
          onConfirm={onSave}
        >
          <p>
            This will replace your existing root folder. References to Box files
            and folders in existing content may be unreachable if the existing
            folder mappings are outside of the new root folder&apos;s hierarchy.
          </p>
        </ConfirmModal>
      )}
    </>
  );
};
