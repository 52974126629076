import * as React from 'react';
import cx from 'classnames';
import * as Type from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useJourneyState } from 'contexts/journeys/journey';
import { UNNAMED_JOURNEY } from 'models/journeys/journey';
import JourneyStatusTagMenu from '../JourneyStatusTagMenu';
import styles from './journey-canvas-header.module.css';

export const TitleBar: React.FC<{
  canEditTitle: boolean;
}> = ({ canEditTitle }) => {
  const { journey, updateName, journeyMode, updateJourney } = useJourneyState();
  const title = journey?.name || '';
  return (
    <Flex className={styles.journeyHeading}>
      <Type.Subheading color={Type.color.gray40}>
        Create Journey
      </Type.Subheading>
      <Box color={Type.color.gray40} padding={[0, 8, 0, 12]}>
        /
      </Box>
      {canEditTitle ? (
        <EditableTitle
          title={title ?? ''}
          updateTitle={updateName}
          maxLength={100}
        />
      ) : (
        <PlainTitle title={title ?? ''} visible={!!title} />
      )}
      {journey && (
        <JourneyStatusTagMenu
          journey={journey}
          mode={journeyMode}
          updateJourney={updateJourney}
        />
      )}
    </Flex>
  );
};

const EditableTitle: React.FC<{
  title: string;
  updateTitle: (title: string) => void;
  maxLength?: number;
}> = ({ title, updateTitle, maxLength }) => {
  const labelRef = React.useRef<HTMLDivElement>(null);

  const [titleValue, setTitleValue] = React.useState(title);
  const [width, setWidth] = React.useState(100);
  const [isEditing, setIsEditing] = React.useState(false);

  const onResize = () => {
    if (!labelRef.current) return;
    setWidth(labelRef.current.offsetWidth);
  };

  useResizeObserver({ ref: labelRef, onResize });

  const handleChange: React.FocusEventHandler<HTMLInputElement> = (evt) => {
    const value = maxLength
      ? evt.currentTarget.value.slice(0, maxLength)
      : evt.currentTarget.value;
    setTitleValue(value);
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (titleValue !== title) {
      const isBlankTitle = !titleValue?.trim().length;
      if (isBlankTitle) {
        setTitleValue(UNNAMED_JOURNEY);
        updateTitle(UNNAMED_JOURNEY);
      } else {
        updateTitle(titleValue);
      }
    }
  };

  const labelClasses = cx(styles.editableTitleLabel, {
    [styles.editableTitleLabelHidden]: isEditing,
  });

  const inputClasses = cx(styles.editableTitleInput, {
    [styles.editableTitleInputHidden]: !isEditing,
  });

  return (
    <div className={styles.editableTitle}>
      <div ref={labelRef} className={labelClasses}>
        {titleValue}
      </div>
      <input
        type="text"
        value={titleValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={inputClasses}
        style={{ width }}
        maxLength={maxLength}
        title={titleValue}
      />
    </div>
  );
};

const PlainTitle: React.FC<{ visible: boolean; title: string }> = ({
  visible,
  title,
}) =>
  !visible ? null : (
    <Type.Subheading bold color={Type.color.gray90}>
      {title}
    </Type.Subheading>
  );
