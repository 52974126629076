import snakecaseKeys from 'snakecase-keys';
import { Design } from 'models/design';
import {
  DefinitionBlock,
  RenderingVariables,
  Styling,
} from 'models/publisher/block';
import { defaultDesign } from 'contexts/design';
import { PaginationData } from './common';
import { request, deepCamelcaseKeys } from './api-shared';
import { resolveBlocks } from './api-content-blocks';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}/v2`;

export type FetchProps = {
  programId: number;
  pageSize?: number;
  page?: number;
  onError?: () => void;
};

export type DesignData = {
  attributes: Design;
};

export type DesignsCollectionData = {
  data: Array<DesignData>;
  meta: PaginationData;
};

export type RequestData = {
  id?: string;
  name?: string;
  blocks?: Array<DefinitionBlock>;
  meta?: RenderingVariables;
  styles?: Styling;
};

export type CopyDesignProps = {
  id: number;
  sourceType?: 'design' | 'content' | 'template';
};

function mapDesignToRequestData(design: Partial<Design>): RequestData {
  return {
    id: design.id || 'new',
    name: design.name || '',
    blocks: design.blocks || [],
    meta: design.meta,
    styles: design.styles,
  };
}

export const fetchDesigns = async (
  props: FetchProps
): Promise<DesignsCollectionData> => {
  const { programId } = props;

  const url = `${apiRoot}/programs/${programId}/designs`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then(deepCamelcaseKeys);
  }
  throw new Error(`Error fetching designs: ${response.status}`);
};

export const fetchById = async (
  programId: number,
  designId: number | 'new'
): Promise<Design> => {
  if (designId === 'new') {
    return {
      ...defaultDesign,
    };
  }

  const url = `${apiRoot}/programs/${programId}/designs/${designId}`;
  const response = await request(url);
  if (response.status === 200) {
    const json = await response.json();

    const result = json.data;
    const blocksToResolve = result.attributes.blocks;

    const resolvedBlocks = await resolveBlocks(
      programId,
      blocksToResolve || []
    );

    return { ...result.attributes, blocks: resolvedBlocks };
  }
  throw new Error(`Error fetching design: ${response.status}`);
};

export const fetchStatusById = async (
  programId: number,
  designId: number | 'new'
): Promise<string> => {
  if (designId === 'new') {
    return '';
  }

  const url = `${apiRoot}/programs/${programId}/designs/${designId}/status`;
  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output.status));
  }
  throw new Error(`Error fetching design status: ${response.status}`);
};

export const upsertDesign = async (
  programId: number,
  design: Design
): Promise<DesignData> => {
  let method: 'POST' | 'PUT';
  let url: string;
  if (design.id === 'new') {
    method = 'POST';
    url = `${apiRoot}/programs/${programId}/designs`;
  } else {
    method = 'PUT';
    url = `${apiRoot}/programs/${programId}/designs/${design.id}`;
  }

  const response = await request(url, {
    method,
    body: JSON.stringify({
      data: {
        attributes: mapDesignToRequestData(design),
      },
    }),
  });

  if (response.status === 200 || response.status === 201) {
    return response.json().then((output) => deepCamelcaseKeys(output.data));
  }

  throw new Error(`Error upserting design: ${response.status}`);
};

export const copyDesign = async (
  programId: number,
  { id, sourceType = 'design' }: CopyDesignProps
): Promise<Design> => {
  const response = await request(
    `${apiRoot}/programs/${programId}/designs/${id}/copy`,
    {
      method: 'POST',
      body: JSON.stringify(snakecaseKeys({ sourceType })),
    }
  );

  if (response.status === 200 || response.status === 201) {
    return response
      .json()
      .then((output) => deepCamelcaseKeys(output.data.attributes));
  }

  throw new Error(`Error creating design: ${response.status}`);
};

export const renderDesign = async (
  programId: number,
  designId?: number
): Promise<{
  data: {
    html: string;
  };
}> => {
  if (!designId) return { data: { html: '' } };

  const response = await request(
    `${apiRoot}/programs/${programId}/designs/${designId}/render`,
    {
      method: 'POST',
    }
  );

  if (response.status === 200 || response.status === 201) {
    return response
      .json()
      .then((output) => deepCamelcaseKeys({ data: output.data }));
  }

  throw new Error(`Error rendering design: ${response.status}`);
};
