import * as React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { useUniqueId } from 'hooks/useUniqueId';
import { Layer } from 'shared/Overlay/Layer';
import { stripSymbols, titleCase } from 'utility/text';
import { CloseAlt, WarningTriangle } from 'shared/icons';
import { Box } from './Box';
import { Popover } from './Popover';
import styles from './styles.module.css';
import { Tooltip } from './Tooltip';

export const Modal: React.FC<{
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  width?: string | number;
  maxHeight?: string | number;
  minPadding?: number;
  innerPadding?: number | number[];
  name: string;
  ariaLabel?: string;
  modalBoxOverflow?: 'visible' | 'auto';
  modalFlexOverflow?: 'visible' | 'auto';
  modalFlexHeight?: '100%' | 'none';
  maxWidth?: string | number;
}> = ({
  name,
  width = 'auto',
  minPadding = 70,
  onClick,
  children,
  maxHeight,
  innerPadding,
  modalBoxOverflow = 'visible',
  modalFlexOverflow = 'auto',
  modalFlexHeight = '100%',
  maxWidth,
  ariaLabel,
}) => (
  <Layer name={name}>
    <Box
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel}
      onClick={onClick}
      height="100vh"
      width="100vw"
      absolute
      style={{
        zIndex: 10000,
        backgroundColor: 'rgba(0,0,0,0.2)',
        overflow: modalBoxOverflow,
      }}
    >
      <Flex
        center
        style={{
          height: modalFlexHeight,
          maxHeight: modalFlexHeight,
          overflow: modalFlexOverflow,
          padding: `${minPadding}px`,
        }}
      >
        <Popover padding={innerPadding}>
          <Box maxHeight={maxHeight} width={width} maxWidth={maxWidth}>
            {children}
          </Box>
        </Popover>
      </Flex>
    </Box>
  </Layer>
);

type FormModalBaseProps = {
  entityText: string;
  actionIcon?: React.ReactElement;
  actionText: string;
  description?: string;
  onCancel(): void;
  width?: string | number;
  minPadding?: number;
  contentPadding?: string;
  secondaryButton?: React.ReactElement;
  disableCloseButton?: boolean;
  hideFooter?: boolean;
};

type FormModalProps =
  | (FormModalBaseProps & {
      onSubmit: () => void;
      submitLabel?: string;
      disabled?: boolean;
      submitButton?: never;
    })
  | (FormModalBaseProps & {
      onSubmit?: never;
      submitLabel?: never;
      disabled?: never;
      submitButton: React.ReactElement;
    });

export const FormModal: React.FC<FormModalProps> = ({
  onCancel,
  onSubmit,
  entityText,
  actionIcon,
  actionText,
  description,
  disabled,
  disableCloseButton,
  submitLabel,
  minPadding,
  contentPadding,
  width,
  submitButton,
  secondaryButton,
  hideFooter,
  children,
}) => {
  const containerId = useUniqueId();
  const cancelId = useUniqueId();
  const [promptForCancel, setPromptForCancel] = React.useState(false);
  const callToActionLabel = React.useMemo(
    () => submitLabel || titleCase(`${actionText} ${entityText}`),
    [actionText, entityText, submitLabel]
  );
  return (
    <Modal
      minPadding={minPadding}
      width={width}
      innerPadding={[8, 0]}
      name={`${stripSymbols(actionText)}-${entityText}`}
      onClick={(e) => {
        const contained = document
          .getElementById(containerId)
          ?.contains(e.target as HTMLElement);
        if (!contained && !promptForCancel) {
          setPromptForCancel(true);
          setTimeout(() => {
            document.getElementById(cancelId)?.focus();
          }, 10);
        } else if (contained && promptForCancel) {
          setPromptForCancel(false);
        }
      }}
    >
      <div id={containerId} className={styles.formModal}>
        <Flex spread alignStart className={styles.formModalHeader}>
          <Flex column alignStart>
            <Flex className={styles.formModalHeaderTitle}>
              {actionIcon && actionIcon}
              <Text.Heading semibold>
                {titleCase(`${actionText} ${entityText}`)}
              </Text.Heading>
            </Flex>
            {description && (
              <Box margin={[0, 0, '12px', 0]}>
                <Text.Body color={Text.color.gray60}>{description}</Text.Body>
              </Box>
            )}
          </Flex>
          <Button
            disabled={disableCloseButton}
            onClick={onCancel}
            layoutOnly
            icon={<CloseAlt />}
          />
        </Flex>
        <hr />
        <Box
          className={styles.formModalBody}
          padding={contentPadding ?? '24px'}
        >
          {children}
        </Box>
        {!hideFooter && (
          <>
            <hr />
            <Flex end className={styles.formModalFooter}>
              {secondaryButton || (
                <Button
                  focused={promptForCancel}
                  secondary
                  id={cancelId}
                  onClick={onCancel}
                  label="Cancel"
                />
              )}
              <Space row size={12} />
              {submitButton ?? (
                <Button
                  disabled={disabled}
                  onClick={onSubmit}
                  label={callToActionLabel}
                />
              )}
            </Flex>
          </>
        )}
      </div>
    </Modal>
  );
};

type ConfirmModalBaseProps = {
  title: string;
  titleIcon?: React.ReactNode;
  description?: string;
  disableCancel?: boolean;
  hideCancel?: boolean;
  cancelLabel?: string;
  onCancel(): void;
  width?: string | number;
  minPadding?: number;
  actionElements?: React.ReactNode;
  maxWidth?: string | number;
  confirmTooltipLabel?: string;
};

type ConfirmModalProps = ConfirmModalBaseProps &
  (
    | {
        disabled?: boolean;
        onConfirm(): void;
        confirmIcon?: string | React.ReactNode;
        confirmLabel: string;
        confirmButton?: never;
      }
    | {
        disabled?: never;
        onConfirm?: never;
        confirmIcon?: never;
        confirmLabel?: never;
        confirmButton: React.ReactElement;
      }
  );

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  titleIcon,
  description,
  disableCancel,
  hideCancel,
  confirmLabel,
  confirmTooltipLabel,
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel,
  disabled,
  minPadding,
  width = 680,
  children,
  confirmIcon,
  confirmButton,
  actionElements,
  maxWidth,
}) => {
  const containerId = useUniqueId();
  const confirmTooltipDisabled = Boolean(!disabled) || !confirmTooltipLabel;
  return (
    <Modal
      minPadding={minPadding}
      width={width}
      maxWidth={maxWidth}
      name={`confirm-model-${containerId}`}
      ariaLabel={title}
      innerPadding={0}
      onClick={(e) => {
        const contained = document
          .getElementById(containerId)
          ?.contains(e.target as HTMLElement);
        if (!contained && !disableCancel) {
          onCancel();
        }
      }}
    >
      <div id={containerId}>
        <div className={styles.ConfirmModalHeader}>
          <Flex spread>
            <Text.Heading bold>
              <Flex>
                {titleIcon && (
                  <Box margin={[0, 8, 0, 0]}>
                    <Flex center>{titleIcon}</Flex>
                  </Box>
                )}
                <Box>{title}</Box>
              </Flex>
            </Text.Heading>
            <Button
              onClick={onCancel}
              disabled={disableCancel}
              style={{
                cursor: disableCancel ? 'not-allowed' : 'pointer',
              }}
              clearText
              label={<Text.Heading>×</Text.Heading>}
            />
          </Flex>
          {description && (
            <Text.Body color={Text.color.gray60}>{description}</Text.Body>
          )}
        </div>
        {children && <div className={styles.ConfirmModalBody}>{children}</div>}
        <Flex end className={styles.ConfirmModalActions}>
          {actionElements}
          {actionElements && <Space row size={24} />}

          {!hideCancel && (
            <>
              <Button
                secondary
                onClick={onCancel}
                label={cancelLabel}
                disabled={disableCancel}
                style={{
                  cursor: disableCancel ? 'not-allowed' : 'pointer',
                }}
              />
              <Space row size={12} />
            </>
          )}
          {confirmButton ?? (
            <Tooltip
              disabled={confirmTooltipDisabled}
              offset={-5}
              placement="top-end"
              content={confirmTooltipLabel}
            >
              <Button
                aria-disabled={disabled}
                onClick={onConfirm}
                label={confirmLabel}
                icon={confirmIcon}
                style={{
                  cursor: disabled ? 'not-allowed' : 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Flex>
      </div>
    </Modal>
  );
};

type ErrorModalProps = {
  title: string;
  confirmLabel?: string;
  maxWidth?: string | number;
  onConfirm: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({
  title,
  confirmLabel = 'Ok',
  maxWidth = 680,
  onConfirm,
  children,
}) => (
  <Modal
    innerPadding={0}
    maxWidth={maxWidth}
    name={`error-modal-${useUniqueId()}`}
  >
    <Flex start className={styles.ErrorModalHeader}>
      <WarningTriangle viewBox="0 1 24 24" />
      <Box padding={[0, 0, 0, 6]}>{title}</Box>
    </Flex>
    {children && <div className={styles.ErrorModalBody}>{children}</div>}
    <Flex end className={styles.ErrorModalFooter}>
      <Button label={confirmLabel} onClick={onConfirm} />
    </Flex>
  </Modal>
);
